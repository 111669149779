<template>
  <AuthPagesLayout>
    <img
      v-if="isSentEmail"
      src="@/assets/images/svg/auth-pages-forgot-password.svg?data=raw"
      alt=""
      class="mb-2"
    />

    <div>
      <h3 class="text-trail-display2 text-trail-text-display-normal">
        {{
          isSentEmail
            ? $t('scenes.forgot.password.sent-email-title')
            : $t('scenes.forgot.password.title')
        }}
      </h3>

      <p class="text-trail-body2 text-trail-text-body-normal mt-2">
        {{
          isSentEmail
            ? $t('scenes.forgot.password.sent-email-instruction')
            : $t('scenes.forgot.password.information')
        }}
      </p>
    </div>

    <Forgot v-if="!isSentEmail" @showInstruction="isSentEmail = true" />

    <TrailButton
      variant="secondary"
      data-testid="forgot-password-back-2-login-link"
      @click="$router.push({ name: 'sign-in' })"
    >
      {{ $t('scenes.forgot.password.back-to-sign-in') }}
    </TrailButton>
  </AuthPagesLayout>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { definePage } from 'vue-router/auto'

definePage({
  name: 'forgot-password',
})

const isSentEmail = ref<boolean>(false)
</script>
