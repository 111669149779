<template>
  <div class="relative" data-testid="sign-up-page">
    <LandingHeaderBar class="sticky top-0" data-testid="header-bar" />

    <div data-testid="main-content">
      <ValuePropositionSectionHero
        class="px-6 pb-12 md:pb-0"
        data-testid="hero-section"
      />

      <ValuePropositionPrograms data-testid="programs-section" />

      <ValuePropositionFeatures class="px-6" data-testid="features-section" />

      <ValuePropositionSteps class="px-6" data-testid="steps-section" />

      <ValuePropositionReadyAction
        class="px-6"
        data-testid="ready-action-section"
      />

      <ValuePropositionFooter class="px-6" data-testid="footer-section" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { definePage } from 'vue-router/auto'
import ValuePropositionReadyAction from '@/components/ValueProposition/ValuePropositionReadyAction.vue'

definePage({
  meta: { requiresAuth: false },
  name: 'sign-up',
})
</script>
