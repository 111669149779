<template>
  <button
    :class="[cursorClass, paddingClass]"
    :disabled="isDisabled"
    data-testid="trailButton"
  >
    <div
      :class="[
        cursorClass,
        sizeClass,
        colorClass,
        'relative flex items-center justify-center rounded-lg whitespace-nowrap transition-colors',
      ]"
      data-testid="trailButtonWrapper"
    >
      <div class="absolute left-1/2 -translate-x-1/2">
        <TrailIcon
          v-if="loading"
          icon="local:spinner"
          class="animate-spin"
          data-testid="trailButtonLoader"
        />
      </div>

      <div
        :class="['truncate', { invisible: loading }]"
        data-testid="trailButtonContent"
      >
        <slot />
      </div>
    </div>
  </button>
</template>

<script setup lang="ts">
import { computed } from 'vue'

export type TrailButtonProps = {
  variant?: 'primary' | 'secondary' | 'tertiary'
  size?: 'small' | 'normal'
  destructive?: boolean
  disabled?: boolean
  loading?: boolean
  icon?: boolean
}

const props = withDefaults(defineProps<TrailButtonProps>(), {
  variant: 'primary',
  size: 'normal',
})

const isDisabled = computed(() => props.disabled || props.loading)

const cursorClass = computed(() => {
  if (isDisabled.value) {
    return 'cursor-not-allowed'
  }

  return 'cursor-pointer'
})

const sizeClass = computed(() => {
  if (props.icon) {
    return 'w-11 h-11 p-0'
  }

  if (props.size === 'small') {
    return 'min-w-28 h-9 px-2 text-trail-title5 leading-5'
  }

  return 'min-w-28 h-11 px-6 text-trail-title4 leading-6'
})

const paddingClass = computed(() => {
  if (props.size === 'small' && !props.icon) {
    return 'py-1'
  }

  return ''
})

const colorClassMapping = {
  'primary-nondestructive-enabled': [
    'text-trail-text-title-on_dark',
    'bg-trail-surface-primary-highlight',
    'hover:bg-trail-surface-secondary-highlight',
  ],
  'primary-nondestructive-loading': [
    'text-trail-text-title-on_dark',
    'bg-trail-surface-secondary-highlight',
  ],
  'primary-nondestructive-disabled': [
    'text-trail-text-title-on_dark',
    'bg-trail-surface-primary-disabled',
  ],
  'primary-destructive-enabled': [
    'text-trail-text-title-on_dark',
    'bg-trail-surface-primary-negative',
    'hover:bg-trail-surface-secondary-negative',
  ],
  'primary-destructive-loading': [
    'text-trail-text-title-on_dark',
    'bg-trail-surface-secondary-negative',
  ],
  'primary-destructive-disabled': [
    'text-trail-text-title-on_dark',
    'bg-trail-surface-primary-disabled',
  ],
  'secondary-nondestructive-enabled': [
    'text-trail-text-title-normal',
    'border',
    'border-trail-border-normal',
    'hover:bg-trail-surface-tertiary-normal',
  ],
  'secondary-nondestructive-loading': [
    'text-trail-text-title-normal',
    'border',
    'border-trail-border-normal',
    'bg-trail-surface-tertiary-normal',
  ],
  'secondary-nondestructive-disabled': [
    'text-trail-text-title-disabled',
    'border',
    'border-trail-border-disabled',
  ],
  'secondary-destructive-enabled': [
    'text-trail-text-title-negative',
    'border',
    'border-trail-border-negative',
    'hover:bg-trail-surface-tertiary-normal',
  ],
  'secondary-destructive-loading': [
    'text-trail-text-title-negative',
    'border',
    'border-trail-border-negative',
    'bg-trail-surface-tertiary-normal',
  ],
  'secondary-destructive-disabled': [
    'text-trail-text-title-disabled',
    'border',
    'border-trail-border-disabled',
  ],
  'tertiary-nondestructive-enabled': [
    'text-trail-text-title-normal',
    'hover:bg-trail-surface-tertiary-normal',
  ],
  'tertiary-nondestructive-loading': [
    'text-trail-text-title-normal',
    'bg-trail-surface-tertiary-normal',
  ],
  'tertiary-nondestructive-disabled': ['text-trail-text-title-disabled'],
  'tertiary-destructive-enabled': [
    'text-trail-text-title-negative',
    'hover:bg-trail-surface-tertiary-negative',
  ],
  'tertiary-destructive-loading': [
    'text-trail-text-title-negative',
    'bg-trail-surface-tertiary-negative',
  ],
  'tertiary-destructive-disabled': ['text-trail-text-title-disabled'],
}

const colorClass = computed(() => {
  const destructive = props.destructive ? 'destructive' : 'nondestructive'
  const loadingOrEnabled = props.loading ? 'loading' : 'enabled'
  const disabledOrLoadingOrEnabled = props.disabled
    ? 'disabled'
    : loadingOrEnabled

  return colorClassMapping[
    `${props.variant}-${destructive}-${disabledOrLoadingOrEnabled}`
  ]
})
</script>
