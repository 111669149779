<template>
  <div class="orders">
    <div class="flex justify-between place-items-end">
      <OrderPageHeader
        data-testid="ordersPageHeader"
        :orderPageTitle="t('orderPageTitle')"
        :filterOpened="filterOpened"
        :orderListParams="orderListParams"
        :filterOptions="manualFilterOptions"
        :filterBadgeCount="filterBadgeCount"
        filterType="manual"
        @dateSelected="selectDateOnClose"
        @clearSelectedDate="clearSelectDate"
        @searchFilterChange="searchFilter"
        @applyFilters="applyFilters"
        @updateFilterBadgeCount="updateFilterBadgeCount"
        @changeFilterOpened="changeFilterOpened"
      />

      <div class="flex flex-row items-center gap-4 pb-4">
        <strong class="text-trail-body3">
          {{
            $t('numberOfOrdersSelected', {
              selectedNumber: selectedRows.length,
              totalNumber: paginationData?.total_items,
            })
          }}
        </strong>

        <ManageOrdersTableBulkActions
          data-testid="ordersPageActions"
          :orders="selectedRows"
          :orders-total="paginationData?.total_items"
          :export-selected-limit="exportSelectedLimit"
          :export-all-limit="exportAllLimit"
          @export-selected-orders="exportSelectedOrders"
          @export-all-orders="exportAllOrders"
        />
      </div>
    </div>

    <div class="orders-list">
      <TrailBanner
        v-if="selectedRows.length > exportSelectedLimit"
        data-testid="ordersSelectedLimitExceeded"
        show-icon
        type="warning"
      >
        {{ $t('exportSelectedOrdersLimit', { number: exportSelectedLimit }) }}
      </TrailBanner>

      <OrderTable
        v-model:selectedRows="selectedRows"
        data-testid="ordersTable"
        :rows="orderList"
        :is-loading="loading"
        :pagination="paginationData"
        :is-searched="isSearched"
        order-type="manual"
        @pagination-changed="pageChanged"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { definePage } from 'vue-router/auto'
import { ref, watch, computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { manualFilterOptions } from '@/constant/orders'
import 'flatpickr/dist/flatpickr.css'
import { useApiData } from '@/composables/useApiData'
import { transformOrderListResponse } from '@/api/transforms/ordersTransforms'
import type { Order, OrderFilters } from '@/models/orderModels'
import { downloadFile } from '@/utils/downloadFile'
import { useTrailToast } from '@/composables/Trail/useTrailToast'

definePage({
  name: 'orders',
  meta: {
    requiresAuth: true,
    requiredPermissions: ['list-Modules\\Package\\Models\\Hub\\Order'],
  },
})

const { t } = useI18n({ useScope: 'global' })
const { positive, negative } = useTrailToast()

const currentPage = ref(1)
const rangeDate = ref(null)
const filterOpened = ref(false)
const filterBadgeCount = ref(0)
const isSearched = ref(false)
const selectedRows = ref<[]>([])
const orderListRequestParams = ref('')
const exportSelectedLimit = ref(15000)
const exportAllLimit = ref(15000)
const getOrderAbortController = ref<AbortController | null>(null)

const orderListParams = ref<OrderFilters>({
  selectDate: '',
  filterOption: '',
  search: '',
})

const {
  data: orderListResponse,
  fetchData: fetchOrderList,
  loading,
} = useApiData('/store/v1/orders', {
  transformer: transformOrderListResponse,
  immediate: false,
  params: () => getQueryParams(false),
})

const { error: exportError, createData: exportOrders } = useApiData(
  () => `/store/v1/orders/export`,
  {
    immediate: false,
    config: {
      responseType: 'blob',
    },
    transformer: (data: Blob, headers) => {
      downloadFile(data, headers, `Orders`)
    },
    done: () => {
      positive({
        content: `${t('exportOrdersSuccess')}`,
        timeout: 5000,
      })
    },
  }
)

const getQueryParams = (onlyFilters: boolean): Record<string, unknown> => {
  const query = orderListRequestParams.value.startsWith('?')
    ? orderListRequestParams.value.slice(1)
    : orderListRequestParams.value

  const queryParams = query
    .split('&')
    .reduce((acc: Record<string, string | number>, pair) => {
      const [key, value] = pair.split('=')

      acc[key] = isNaN(Number(value))
        ? decodeURIComponent(value)
        : Number(value)

      return acc
    }, {})

  if (onlyFilters) {
    return {
      filter: {
        code: queryParams['filter[code]'],
        created_at: queryParams['filter[created_at]'],
        status: queryParams['filter[status]'],
        topups: queryParams['filter[topups]'],
        sims: queryParams['filter[sims]'],
      },
    }
  }

  return {
    limit: queryParams['limit'],
    page: currentPage.value,
    'filter[code]': queryParams['filter[code]'],
    'filter[created_at]': queryParams['filter[created_at]'],
    'filter[status]': queryParams['filter[status]'],
    'filter[topups]': queryParams['filter[topups]'],
    'filter[sims]': queryParams['filter[sims]'],
  }
}

const orderList = computed(() => orderListResponse.value?.data ?? [])
const paginationData = computed(() => orderListResponse.value?.pagination_info)

const updateFilterBadgeCount = (badgeCount: number) => {
  filterBadgeCount.value = badgeCount
}

const applyFilters = async (params: keyof OrderFilters) => {
  filterOpened.value = false

  orderListParams.value['filterOption'] = params
}
const changeFilterOpened = (filterStatus: boolean) => {
  filterOpened.value = filterStatus
}

const selectDateOnClose = (selectedDates: string, dateStr: string) => {
  let params = ''
  let dates = dateStr.split('to').map((item) => item.trim())

  if (!dates[0]) return

  dates.forEach((date, index) => {
    if (dates.length === 1) {
      params = `filter[created_at]=${date} - ${date}`
    } else if (index === 0) {
      params = `filter[created_at]=${date}`
    } else {
      params += ` - ${date}`
    }
  })

  if (orderListParams.value.selectDate === params) return

  orderListParams.value.selectDate = params
}

const clearSelectDate = () => {
  rangeDate.value = null
  orderListParams.value.selectDate = ''
}

const searchFilter = (searchString: string) => {
  if (orderListParams.value['search'] !== `filter[code]=${searchString}`) {
    orderListParams.value['search'] = searchString
      ? `filter[code]=${searchString}`
      : ''
  }
}

const pageChanged = (page: number) => {
  currentPage.value = page
}

const exportSelectedOrders = async (orders: Order[]) => {
  if (orders.length > exportSelectedLimit.value) {
    return
  }

  await exportOrders({ ids: orders.map((item: Order) => item.id) })
}

const exportAllOrders = async () => {
  if (
    paginationData.value?.total_items &&
    paginationData.value?.total_items > exportAllLimit.value
  ) {
    return
  }

  await exportOrders(getQueryParams(true))
}

onMounted(() => {
  fetchOrderList()
})

watch(exportError, () => {
  negative({
    content: t('shareModalDownloadError'),
    timeout: 2000,
  })
})

watch(
  [orderListParams],
  () => {
    currentPage.value = 1
  },
  { deep: true }
)

watch(
  [orderListParams, currentPage],
  () => {
    if (getOrderAbortController.value) {
      getOrderAbortController.value.abort()
    }

    getOrderAbortController.value = new AbortController()

    let params = '?limit=25'

    Object.keys(orderListParams.value).forEach((filter: unknown) => {
      if (orderListParams.value[filter as keyof OrderFilters]) {
        params += `&${orderListParams.value[filter as keyof OrderFilters]}`
      }
    })

    orderListRequestParams.value = params.replace('&&', '&')
    fetchOrderList()
  },
  { deep: true }
)
</script>

<style lang="scss" scoped>
.orders {
  .orders-header {
    @apply flex justify-between mb-0 pb-4 border-solid border-[#CACACB];
    .orders-header-filter {
      .orders-header-filter-input {
        @apply ltr:pl-10 rtl:pr-10 bg-seashell-100;

        &::placeholder {
          @apply text-sm text-grey-500;
        }
      }

      .orders-header-filter-recentlybox,
      .orders-header-filter-searchbox {
        @apply absolute top-[43px] p-5 pb-[10px] w-full z-[2] bg-[#fafafa] border-[#dddddd] rounded-[7px] z-20;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
        overflow: hidden;

        .order-header-filter-searched:nth-child(2n) p {
          @apply mt-2;
        }

        .filter-searchbox-item {
          @apply flex text-lg items-center text-grey-500 cursor-pointer h-[50px];
          &:nth-child(2n) {
            position: relative;

            &:after {
              content: '';
              display: block;
              position: absolute;
              width: 125%;
              height: 100%;
              left: -25px;
              z-index: -1;
              @apply bg-[#F3F3F3];
            }
          }
        }
      }

      .orders-header-filter-searchbox .filter-searchbox-item {
        &:nth-child(2n):after {
          display: none;
        }

        &:nth-child(2n + 1) {
          position: relative;

          &:after {
            content: '';
            display: block;
            position: absolute;
            width: 125%;
            height: 100%;
            left: -25px;
            z-index: -1;

            @apply bg-[#F3F3F3];
          }
        }
      }

      &-buttons {
        @apply flex items-center gap-[15px] mt-[25px] flex-wrap;
        button {
          @apply cta-button-sm flex items-center gap-[6px] py-[8px] px-[11px] bg-seashell-700 border-[1px] border-solid border-[#B8B8B8] rounded-lg;
        }

        .export-options {
          @apply flex flex-col items-start absolute w-[218px] bg-[#FAFAFA] border-[#dddddd] rounded-[7px] drop-shadow-xl top-[50px] left-0 z-[2];

          .export-option-item-text {
            @apply p-5 cursor-pointer flex items-center max-h-[48px] min-h-[38px] rounded-[4px] text-grey-500;
          }
        }
      }
    }
  }
}

:global(.endRange) {
  @apply shadow-none;
}
</style>
